import React from "react"
import Image from "../components/image"

export const Welcome = () => {
  return (
    <div className="flex flex-col-reverse lg:flex-row items-center mb-6 mt-4 sm:mt-16">
      <div className="lg:mx-8 lg:w-8/12 sm:w-10/12 w-11/12">
        <h1 className="sm:text-5xl text-4xl text-primary font-semibold text-center lg:text-left mb-2">
          Hi, I'm Matt.
        </h1>
        <p className="sm:text-lg text-base text-primary text-center lg:text-left mb-4">
          I'm a Software Engineer based in the UK, currently working remotely from the British countryside.
        </p>
        <p className="sm:text-lg text-base text-primary text-center lg:text-left">
          Over the past {new Date().getFullYear() - 2017}{" "} years I've built software for the aviation, power generation and manufacturing industries. These days, I work on high performance distributed systems in the financial industry.
        </p>
      </div>
      <div className="lg:w-4/12 flex justify-center items-center">
        <div className="h-64 w-64">
          <Image />
        </div>
      </div>
    </div>
  )
}

export default Welcome
